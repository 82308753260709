import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import get from 'lodash/get';
import Img from 'gatsby-image';
import Layout from '../../components/layout/layout';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import styles from './article.module.scss';
import AuthorAttribution from '../../components/author-attribution/author-attribution';
import CategoryBlock from '../../components/category-block/category-block';

interface Props {
  data: any;
}

const ArticleTemplate = ({data}: Props) => {
  const article = get(data, 'contentfulArticle');
  const siteTitle = get(data, 'site.siteMetadata.title');
  return (
    <Layout>
      <div className={styles.article}>
        <Helmet title={`${article.title} | ${siteTitle}`} />
        <div>
          <h2>{article.title}</h2><CategoryBlock category={article.category} />
          <div>By <AuthorAttribution authors={article.author} /> on {article.publishedAt}</div>
        </div>
        <Img
          className={styles.heroImage}
          alt={article.title}
          fluid={article.heroImage.fluid}
        />
        <div>
          {renderRichText(article.body)}
        </div>
      </div>
    </Layout>
  )
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query ArticleBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulArticle(slug: { eq: $slug }) {
      title
      publishedAt(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(maxWidth: 1180, background: "rgb:000000", quality: 100) {
          ...GatsbyContentfulFluid
        }
      }
      body {
        raw
      }
      author {
        alias
        firstName
        lastName
      }
      category {
        title
      }
    }
  }
`
