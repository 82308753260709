import { Link } from 'gatsby';
import React from 'react';
import styles from './category-block.module.scss';

interface Props {
  category: any
}

// Displays an in-line block that presents category name and links to the category page.
const CategoryBlock = ({category}: Props) => (
  <Link to={`/cat/${category.title.toLowerCase()}`}>
    <div className={styles.categoryBlock}>
      {category.title.toUpperCase()}
    </div>
  </Link>
)

export default CategoryBlock;
