import { Link } from 'gatsby';
import React from 'react';

interface Props {
  authors: Array<any>
}
// Fix this so it returns with "and" between and renders properly
const AuthorAttribution = ({authors}: Props) => {
  let attribution: any = [];
  authors.map((author) => {
    if(author.firstName || author.lastName) {
      attribution.push(<Link to={`/author/${author.alias.toLowerCase()}`}>{`${author?.firstName ? author.firstName : ''} \"${author.alias}\" ${author?.lastName ? author.firstName : ''}`}</Link>)
    } else {
      attribution.push(<Link to={`/author/${author.alias.toLowerCase()}`}>{author.alias}</Link>);
    }
  });
  return attribution.join(' and ');
}

export default AuthorAttribution;
